var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"iso2-buttons\">\n    <button class=\"btn btn-primary btn-regular\" data-iso2=\"NL\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"netherlands",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":42}}}))
    + "\n    </button>\n    <button class=\"btn btn-primary btn-regular\" data-iso2=\"BE\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"belgium",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":38}}}))
    + "\n    </button>\n    <button class=\"btn btn-primary btn-regular\" data-iso2=\"GB\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"united_kingdom_of_great_britain_and_n_ireland",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":76}}}))
    + "\n    </button>\n    <button class=\"btn btn-primary btn-regular\" data-iso2=\"\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"other",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":36}}}))
    + "\n    </button>\n</div>\n<input type=\"text\" data-ui=\"input\" class=\"form-control\" placeholder=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"placeholderInput") || (depth0 != null ? lookupProperty(depth0,"placeholderInput") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"placeholderInput","hash":{},"data":data,"loc":{"start":{"line":15,"column":69},"end":{"line":15,"column":89}}}) : helper)))
    + "\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"companyName") || (depth0 != null ? lookupProperty(depth0,"companyName") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"companyName","hash":{},"data":data,"loc":{"start":{"line":15,"column":98},"end":{"line":15,"column":113}}}) : helper)))
    + "\">\n<div class=\"help-block\"></div>\n";
},"useData":true});